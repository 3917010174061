import { EXCEL_STYLES_IDS } from '../../../../common/components/ag-grid';
import { quarterPeriodFormatter } from '../../../../common/utils/formatters';
import { RestrictionTypeEnum, UNLIMITED } from '../../../utils/const';
import { getCellData } from '../../../../common/components/ag-grid/OvexAGTable';

import AvailableCapacityRow from './rowModel/AvailableCapacityRow';
import WeekCellRenderer from './WeekCellRenderer/WeekCellRenderer';
import { convertWeekDetailListToViewStructure, weekTableHeaderName } from './availableCapacityUtils';
import { getContextMenuReadOnlyMode } from './AvailableCapacityTableContextMenuItems';
import ACRowTypeEnum from './rowModel/ACRowTypeEnum';
import {
  countModelGroupWithRestrictions,
  findQuotaByModelGroup,
  findQuotas
} from './AvailableCapacityTableData.helpers';
import RestrictionCellRenderer from './RestrictionCellRenderer/RestrictionCellRenderer';

export const RESTRICTION_COLUMN_IDS = {
  MODEL_GROUP: 'modelGroup',
  RESTRICTION_TYPE: 'restrictionType',
  MODEL_MASK: 'modelMask',
  COLOUR: 'colour',
  INTERIOR: 'interior',
  PR_NUMBER: 'prNumber',
  VALUE: 'value'
};
const ROW_SPAN_LABELS = 3;
const COL_SPAN_LABELS = 6;
const CELL_SPAN_RULES = () => {
  return {
    'cell-span cell-span-center': (params) => {
      return ACRowTypeEnum.isRestriction(params.data.type);
    }
  };
};
class AvailableCapacityTableData {

  /**
   * Definice sloupců pro restrikce.
   *
   * @param lsi
   */
  static restrictionColumns = (lsi) => {
    return [{
      groupId: 'restrictionHeader',
      headerName: '',
      marryChildren: true,
      children: [
        {
          colId: RESTRICTION_COLUMN_IDS.MODEL_GROUP,
          field: 'modelGroup',
          headerName: lsi.getLSIItem('PVT.TABLE_HEADER.GROUP'),
          width: 90,
          type: ['restrictionDefinitionColumn'],
          sortable: true,
          filter: true,
          suppressColumnsToolPanel: true,
          filterParams: { newRowsAction: 'keep' },
          menuTabs: ['filterMenuTab', 'columnsMenuTab'],
          rowSpan: function (params) {
            const isROC = (ACRowTypeEnum.isROC(params?.data?.type));
            const restrictionCount = isNaN(params.data.restrictionCount) ? 0 : params.data.restrictionCount;
            return isROC ? restrictionCount + ROW_SPAN_LABELS : 0;
          },
          cellClass: ['AvailableCapacity-column--modelGroup', EXCEL_STYLES_IDS.STRING],
          cellRendererFramework: RestrictionCellRenderer
        },
        {
          colId: RESTRICTION_COLUMN_IDS.VALUE,
          field: 'value',
          headerName: lsi.getLSIItem('PVT.TABLE_HEADER.VALUE'),
          width: 85,
          type: ['restrictionDefinitionColumn'],
          suppressColumnsToolPanel: true,
          colSpan: function (params) {
            return params?.data?.value && !ACRowTypeEnum.isRestriction(params.data.type) ? COL_SPAN_LABELS : 1;
          },
          rowSpan: function (params) {
            return params?.data?.value && ACRowTypeEnum.isRestriction(params.data.type) ? params.data.restrictionCount : 0;
          },
          cellClass: EXCEL_STYLES_IDS.STRING,
          cellClassRules: {
            'cell-span': params => {
              return params.data.value && !ACRowTypeEnum.isRestriction(params.data.type);
            },
            'ag-cell-last-left-pinned': (params) => {
              return params?.data?.value && !ACRowTypeEnum.isRestriction(params.data.type);
            },
            'restriction': (params) => {
              return params.data.value && ACRowTypeEnum.isRestriction(params.data.type);
            }
          }
        },
        {
          colId: RESTRICTION_COLUMN_IDS.RESTRICTION_TYPE,
          field: 'restrictionType',
          headerName: lsi.getLSIItem('PVT.TABLE_HEADER.RESTRICTION_TYPE'),
          width: 50,
          type: ['restrictionDefinitionColumn'],
          cellClass: EXCEL_STYLES_IDS.STRING,
          cellClassRules: CELL_SPAN_RULES(),
          headerTooltip: lsi.getLSIItem('PVT.TOOLTIP.RESTRICTION_TYPE'),
          valueFormatter: p => this._restrictionTypeFormatter(p, lsi),
          hide: true
        },
        {
          colId: RESTRICTION_COLUMN_IDS.MODEL_MASK,
          field: 'modelMask',
          headerName: lsi.getLSIItem('PVT.TABLE_HEADER.MASK'),
          width: 65,
          type: ['restrictionDefinitionColumn'],
          cellClassRules: CELL_SPAN_RULES(),
          cellClass: EXCEL_STYLES_IDS.STRING
        },
        {
          colId: RESTRICTION_COLUMN_IDS.COLOUR,
          field: 'colour',
          headerName: lsi.getLSIItem('PVT.TABLE_HEADER.COLOUR'),
          width: 65,
          type: ['restrictionDefinitionColumn'],
          cellClassRules: CELL_SPAN_RULES(),
          cellClass: EXCEL_STYLES_IDS.STRING
        },
        {
          colId: RESTRICTION_COLUMN_IDS.INTERIOR,
          field: 'interior',
          headerName: lsi.getLSIItem('PVT.TABLE_HEADER.INTERIOR'),
          width: 70,
          type: ['restrictionDefinitionColumn'],
          cellClassRules: CELL_SPAN_RULES(),
          cellClass: EXCEL_STYLES_IDS.STRING
        },
        {
          colId: RESTRICTION_COLUMN_IDS.PR_NUMBER,
          field: 'prNumber',
          headerName: lsi.getLSIItem('PVT.TABLE_HEADER.PR_NUMBER'),
          headerTooltip: lsi.getLSIItem('PVT.TOOLTIP.PR_NUMBER'),
          width: 85,
          type: ['restrictionDefinitionColumn'],
          cellClassRules: CELL_SPAN_RULES(),
          cellClass: EXCEL_STYLES_IDS.STRING
        }
      ]
    }];
  };

  /**
   * Definice sloupců pro týdení výrobní kapacitu.
   *
   * @param {Array.<model/WeekDetailDTO>} weekDetailList
   * @param lsi
   */
  static restrictionWeekColumns = (weekDetailList, lsi) => {

    return convertWeekDetailListToViewStructure(weekDetailList).map(yearStructure => {
      return {
        groupId: yearStructure.groupId,
        headerName: yearStructure.year,
        suppressColumnsToolPanel: true,
        headerClass: 'ovex-AvailableCapacityTable-column-headerSeparator-quarter',
        marryChildren: true,
        children: yearStructure.subItemList.map(quarterStructure => ({
          groupId: quarterStructure.groupId,
          headerName: quarterStructure.year + quarterPeriodFormatter(quarterStructure.quarter),
          headerClass: 'ovex-AvailableCapacityTable-column-headerSeparator-quarter',
          marryChildren: true,
          children: quarterStructure.subItemList.map(monthStructure => ({
            groupId: monthStructure.groupId,
            headerName: lsi.getLSIItem(`COMMON.ENUM_LABELS.MONTH.${monthStructure.month}`) + ' ' + monthStructure.year,
            headerClass: (headerClassParams) => {
              return headerClassParams.colDef.ovLastMonthOfQuarter ? 'ovex-AvailableCapacityTable-column-headerSeparator-quarter' : 'ovex-AvailableCapacityTable-column-headerSeparator-month';
            },
            marryChildren: true,
            // 'ov' = OVEX3 properties, used to set ccs classes
            ovLastMonthOfQuarter: monthStructure.lastMontOfQuarter,
            children: monthStructure.subItemList.map(weekDetail => ({
              colId: weekDetail.pvtWeek,
              field: weekDetail.pvtWeek,

              headerName: weekTableHeaderName(weekDetail.pvtWeek),
              width: 80,
              suppressMenu: true,
              headerClass: (headerClassParams) => {
                const headerClasses = ['ovex-ag-grid--th-center'];
                const wd = headerClassParams.colDef.ovWeekDetail;
                wd.lastWeekOfQuarter && headerClasses.push('ovex-AvailableCapacityTable-column-headerSeparator-quarter');
                wd.lastWeekOfMonth && headerClasses.push('ovex-AvailableCapacityTable-column-headerSeparator-month');
                return headerClasses;
              },
              cellClass: (cellClassParams) => {
                const cellClasses = ['ovex-ag-grid--renderer-fill-cell'];
                const wd = cellClassParams.colDef.ovWeekDetail;
                wd.lastWeekOfQuarter && cellClasses.push('ovex-AvailableCapacityTable-column-lastWeekOfQuarter');
                wd.lastWeekOfMonth && cellClasses.push('ovex-AvailableCapacityTable-column-lastWeekOfMonth');
                (wd.firstWeekOfQuarter && ACRowTypeEnum.isROC(cellClassParams.data.type)) && cellClasses.push('ovex-AvailableCapacityTable-column-lastWeekOfQuarter');
                return cellClasses;
              },
              colSpan: (params) => {
                return params.data && ACRowTypeEnum.isROC(params.data.type) ? this.countWeeksInQuarter(quarterStructure) : 1;
              },
              cellRendererFramework: WeekCellRenderer,
              valueGetter: (params) => {
                return this._handleValueGetter(params, lsi);
              },
              suppressMovable: true,
              // 'ov' = OVEX3 properties, used to set ccs classes
              ovWeekDetail: weekDetail
            }))
          }))
        }))
      };
    });
  };

  /**
   * Obsah tabulky - transformace z {@link Array.<model/DealerRestrictionDTO>}
   *
   * @param {DealerRestrictionDTO[]} restrictions
   * @param lsi lsiContext
   */
  static transformToRestrictionRows = (restrictions, lsi) => {
    if (!Array.isArray(restrictions)) {
      return [];
    }
    let restrictionRows = [];
    let modelGroup = '';
    const modelGroupsRestrictionsCounter = countModelGroupWithRestrictions(restrictions);
    const allQuotas = findQuotas(restrictions);
    let modelGroupRestrictions = [];

    restrictions.forEach(restrictionDTO => {
      let actualModelGroup = restrictionDTO.modelGroup;
      const restrictionCount = modelGroupsRestrictionsCounter[actualModelGroup];
      const restrictionCountNumber = isNaN(restrictionCount) ? 0 : restrictionCount;

      if (actualModelGroup !== modelGroup) {
        const quota = findQuotaByModelGroup(allQuotas, actualModelGroup);
        restrictionRows.push(AvailableCapacityRow.constructFromRestrictionDTO(quota, ACRowTypeEnum.ROC, lsi.getLSIItem('PVT.LABEL.AVAILABLE_Q_CAPACITY_ROC'), restrictionCountNumber));
        restrictionRows.push(AvailableCapacityRow.constructFromRestrictionDTO(quota, ACRowTypeEnum.QUOTA, lsi.getLSIItem('PVT.LABEL.AVAILABLE_QUOTA'), restrictionCountNumber));
        restrictionRows.push(AvailableCapacityRow.constructFromRestrictionDTO(quota, ACRowTypeEnum.QUOTA_NON_RESTRICTIVE, lsi.getLSIItem('PVT.LABEL.AVAILABLE_CAPACITY_NON_RESTRICTIVE'), restrictionCountNumber));
      }

      if (restrictionDTO.restrictionType !== 'Q') {
        const existsModelGroup = modelGroupRestrictions.includes(restrictionDTO.modelGroup);
        const message = existsModelGroup ? null : lsi.getLSIItem('PVT.LABEL.RESTRICTION');

        if (!existsModelGroup) {
          modelGroupRestrictions.push(restrictionDTO.modelGroup);
        }

        restrictionRows.push(AvailableCapacityRow.constructFromRestrictionDTO(restrictionDTO, ACRowTypeEnum.RESTRICTION, message, restrictionCountNumber));
      }
      modelGroup = actualModelGroup;
    });
    return restrictionRows;
  };

  //-------------------------------------------------------------------------------
  //----------------------------------HELPER METHODS------------------------------
  //-------------------------------------------------------------------------------

  /**
   * Get value of cell
   * @param params AvailableCapacityRow
   * @param lsi
   */
  static _handleValueGetter = (params, lsi) => {
    const cellData = getCellData(params);
    if (cellData == null) {
      return null;
    }

    return this._getValueBasedOnType(cellData, lsi);
  };
  /**
   * Return value based on type of row
   * @param cellData data from cell
   * @param lsi
   */
  static _getValueBasedOnType = (cellData, lsi) => {
    let value = cellData.get;
    if (ACRowTypeEnum.isROC(cellData.type)) {
      value = cellData.getROCValue();
      return value === UNLIMITED ? lsi.getLSIItem('PVT.LABEL.UNLIMITED') : value;
    }

    if (ACRowTypeEnum.isQuota(cellData.type) || ACRowTypeEnum.isRestriction(cellData.type)) {
      return cellData.getQuotaValue();
    }

    if (ACRowTypeEnum.isQuotaNonRestrictive(cellData.type)) {
      return cellData.getQuotaNonRestrictiveValue();
    }

    return value; // Return original value if no condition is met
  };

  /**
   * Counting weeks in one quarter
   * @param quarterStructure
   * @returns {*}
   */
  static countWeeksInQuarter = (quarterStructure) => {
    return quarterStructure.subItemList.reduce((totalWeeks, month) => {
      return totalWeeks + month.subItemList.length;
    }, 0);
  }

  static getContextMenuItems = (params) => {
    return getContextMenuReadOnlyMode(params);
  };

  static getRowHeight = (params) => {
    return params.node.rowHeight;
  };

  static rowClassRules = {
    'row-black-line-top': (params) => {
      return params.data && ACRowTypeEnum.isROC(params.data.type);
    }
  };

  static _restrictionTypeFormatter = (params, lsi) => {
    return (params.value !== RestrictionTypeEnum.Q) ? lsi.getLSIItem('PVT.ENUM_LABELS.RESTRICTION_TYPE_SHORT.' + params.value) : '';
  }

  static columnTypes = {
    restrictionDefinitionColumn: {
      pinned: 'left',
      sortable: false,
      filter: false,
      menuTabs: [],
      suppressMovable: true
    }
  };
}

export default AvailableCapacityTableData;
