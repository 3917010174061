import ColumnUIDefinitionCodes from '../ColumnUIDefinitionCodes';
import AbstractColumnUIDefinition from '../AbstractColumnUIDefinition';
import { CURRENCY_SYMBOL } from '../../../../../../utils/const';
import { amountValueFormatter, resolveCellClass } from '../../ValidationResultPGItemTable.helpers';
import { isGroupNode, nonGroupNode } from '../../../../../../../common/components/ag-grid';

class ClaimAmountCUID extends AbstractColumnUIDefinition {

  /** @override */
  getCode = () => {
    return ColumnUIDefinitionCodes.CLAIM_AMOUNT;
  }
  /** @override */
  buildColumnDefinition = (columnUIDefinition, lsi, additionalParams) => {
    const { visible } = columnUIDefinition;
    const { columnTypeList, currency } = additionalParams;

    return {
      colId: this.getCode(),
      hide: !visible,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.AMOUNT', CURRENCY_SYMBOL[currency]),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.AMOUNT', CURRENCY_SYMBOL[currency]),
      cellClass: resolveCellClass('ovex-ag-grid--td-right'),
      valueGetter: (valueGetterParams) => {
        const { data } = valueGetterParams;
        if (isGroupNode(valueGetterParams)) {
          const accountPartData = valueGetterParams.node.allLeafChildren[0].data.accountPartData;
          return accountPartData.amount;
        }
        if (nonGroupNode(valueGetterParams)) {
          return data.claimAmount;
        }
        return null;
      },
      valueFormatter: amountValueFormatter,
      type: [...columnTypeList],
      filter: 'agNumberColumnFilter',
      pinned: 'left',
      width: 163,
      minWidth: 160
    };
  }
}

const claimAmountCUID = new ClaimAmountCUID();

export default claimAmountCUID;