import {
  conditionTypeTooltipValueGetter,
  conditionTypeValueFormatter,
  currentStateCellClass,
  currentStateValueFormatter,
  currentStateValueGetter,
  requiredStateValueFormatter,
  requiredStateValueGetter
} from './ValidationResultHeaderTable.helpers';

export const COLUMN_IDS = {
  CONDITION_TYPE: 'conditionType',
  REQUIRED_STATE: 'requiredState',
  CURRENT_STATE: 'currentState'
};

const COLUMN_TYPE_ENUM = {
  DEFAULT_CD: 'defaultDefinitionColumn'
};

export const columnTypes = {
  [COLUMN_TYPE_ENUM.DEFAULT_CD]: {
    menuTabs: []
  }
};

export const getColumnDefinitions = (lsi) => {

  return [
    {
      colId: COLUMN_IDS.CONDITION_TYPE,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.PRICE_GUARANTEE_PARAMS'),
      cellClass: ['ovex-pg-ValidationResultHeaderTable-column--header'],
      valueFormatter: conditionTypeValueFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 250,
      minWidth: 208,
      tooltipValueGetter: conditionTypeTooltipValueGetter
    },
    {
      colId: COLUMN_IDS.REQUIRED_STATE,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.STATE_REQUIRED'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.STATE_REQUIRED'),
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      valueGetter: requiredStateValueGetter,
      valueFormatter: requiredStateValueFormatter,
      flex: 1,
      minWidth: 280,
      resizable: true
    },
    {
      colId: COLUMN_IDS.CURRENT_STATE,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.STATE_CURRENT'),
      headerTooltip: [
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.VALIDATOR_CURRENT_STATUS'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.VALIDATOR_CURRENT_STATUS_SENT'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.VALIDATOR_CURRENT_STATUS_OK'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.VALIDATOR_CURRENT_STATUS_REPAIRABLE'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.VALIDATOR_CURRENT_STATUS_NOT_REPAIRABLE'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.VALIDATOR_CURRENT_STATUS_ERROR')
      ],
      cellClass: currentStateCellClass,
      valueGetter: currentStateValueGetter,
      valueFormatter: currentStateValueFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      flex: 1,
      minWidth: 280,
      resizable: true
    }
  ];
};
