import { useDispatch } from 'react-redux';
import React from 'react';

import { handleChangeLanguage } from '../../../packages/support/redux/modules/actions';

import UserMenu from './UserMenu';

const UserMenuConnector = () => {
  const dispatch = useDispatch();
  const callbacks = React.useMemo(
    () => {
      return {
        onChangeUserLanguage: async (lang) => {
          try {
            await dispatch(handleChangeLanguage(lang));
            window.location.reload();
          } catch (e) {
          }
        }
      };
    },
    [dispatch]
  );
  return (
    <UserMenu
      onChangeLanguage={callbacks.onChangeUserLanguage}
    />
  );

};

export default UserMenuConnector;
