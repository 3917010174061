import React from 'react';
import PropTypes from 'prop-types';
import { Bricks, Forms } from 'uu5g04';
import { PGAccountingDTO } from '@ovex/price-guarantee-web-api';

import { LsiContext } from '../../../../../common/contexts';
import { Checkbox, Code, DatePicker, Number, Select, Text, WeekPicker } from '../../../../../common/components/forms';
import { PriceGuaranteeTypeEnum } from '../../../../utils/const';
import { isReadOnlyInput } from '../PriceGuaranteeEditInputRules';
import PriceGuaranteeTabs from '../PriceGuaranteeTabs';

import './PriceGuaranteeFormHeader.scss';

const propTypes = {
  editRules: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  //namísto priceguarantee, pouze atributy a ne celý objekt
  priceGuarantee: PropTypes.shape({
    accountingBasisList: PropTypes.arrayOf(PropTypes.instanceOf(PGAccountingDTO)),
    commissionCreationDateTo: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    commissionProduceWeekFirstTo: PropTypes.string,
    commissionSellingDateTo: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    daysFromInvoiceToSale: PropTypes.number,
    id: PropTypes.number,
    marketingActionCode: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    twoWeeksAfterSale: PropTypes.bool,
    type: PropTypes.string
  }).isRequired
};

const defaultProps = {
  priceGuarantee: {
    accountingBasisList: [],
    commissionCreationDateTo: null,
    commissionProduceWeekFirstTo: null,
    commissionSellingDateTo: null,
    daysFromInvoiceToSale: null,
    id: null,
    marketingActionCode: null,
    name: null,
    status: null,
    twoWeeksAfterSale: null,
    type: null
  }
};

export const InputNames = {
  marketingActionCode: 'marketingActionCode',
  name: 'name',
  commissionCreationDateTo: 'commissionCreationDateTo',
  commissionProduceWeekFirstTo: 'commissionProduceWeekFirstTo',
  commissionSellingDateTo: 'commissionSellingDateTo',
  type: 'type',
  daysFromInvoiceToSale: 'daysFromInvoiceToSale',
  twoWeeksAfterSale: 'twoWeeksAfterSale'
};

const PriceGuaranteeForm = (props, ref) => {
  const lsi = React.useContext(LsiContext);

  const readOnly = !props.onSave;

  const formRef = React.useRef(null);
  const handleGetFormRef = React.useCallback(
    (uuFormRef) => {
      formRef.current = uuFormRef;
    },
    []
  );

  React.useImperativeHandle(
    ref,
    () => ({
      isValid: () => formRef.current.isValid(),
      save: () => formRef.current.save()
    })
  );

  const [ pgHeader, setPgHeader ] = React.useState(props.priceGuarantee);

  React.useEffect(() => {
    setPgHeader(props.priceGuarantee);
  }, [props.priceGuarantee]);

  const priceGuaranteeTypeSelectItems = React.useMemo(
    () => Object.values(PriceGuaranteeTypeEnum).map(type => ({
      value: type,
      content: lsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.PRICE_GUARANTEE_TYPE.${type}`),
      tooltip: lsi.getLSIItem(`PRICE_GUARANTEE.TOOLTIP.PRICE_GUARANTEE_TYPE.${type}`)
    })),
    [lsi]
  );
  const onSave = props.onSave;
  const handleSave = React.useCallback(
    ({ values }) => {
      onSave && onSave({ formId: PriceGuaranteeTabs.header, values: values });
    },
    [onSave]
  );

  const handleInputChange = (opt, inputName) => {
    const value = opt.hasOwnProperty('value') ? opt.value : opt;
    setPgHeader({ ...pgHeader, [inputName]: value });
  };

  return (
    <Forms.Form
      className='ovex-pg-PriceGuaranteeFormHeader'
      onCancel={props.onClose}
      onSave={handleSave}
      ref_={handleGetFormRef}
    >
      <Bricks.Row>
        <Bricks.Column colWidth='xs4'>
          <Code
            label={lsi.getLSIItem('PRICE_GUARANTEE.FORM_INPUT_LABEL.MARKETING_ACTION_CODE')}
            maxLength={10}
            name={InputNames.marketingActionCode}
            onChange={(opt) => handleInputChange(opt, InputNames.marketingActionCode)}
            pattern='^[0-9A-Za-z]{8,10}$'
            readOnly={isReadOnlyInput(props.editRules, InputNames.marketingActionCode, readOnly)}
            required
            tooltip={lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.MARKETING_ACTION_CODE')}
            value={pgHeader.marketingActionCode}
          />
        </Bricks.Column>
        <Bricks.Column colWidth='xs8'>
          <Select
            items={priceGuaranteeTypeSelectItems}
            label={lsi.getLSIItem('PRICE_GUARANTEE.FORM_INPUT_LABEL.EVALUATION_VARIANT')}
            name={InputNames.type}
            onChange={(opt) => handleInputChange(opt, InputNames.type)}
            readOnly={isReadOnlyInput(props.editRules, InputNames.type, readOnly)}
            required
            tooltip={lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.PRICE_GUARANTEE_EVALUATION_VARIANT')}
            value={pgHeader.type}
          />
        </Bricks.Column>
      </Bricks.Row>
      <Text
        label={lsi.getLSIItem('PRICE_GUARANTEE.FORM_INPUT_LABEL.NAME')}
        name={InputNames.name}
        onChange={(opt) => handleInputChange(opt, InputNames.name)}
        readOnly={isReadOnlyInput(props.editRules, InputNames.name, readOnly)}
        required
        value={pgHeader.name}
      />
      <Bricks.Row>
        <Bricks.Column colWidth='xs4'>
          <DatePicker
            label={lsi.getLSIItem('PRICE_GUARANTEE.FORM_INPUT_LABEL.COMMISSION_CREATION_DATE_TO')}
            name={InputNames.commissionCreationDateTo}
            onChange={(opt) => handleInputChange(opt, InputNames.commissionCreationDateTo)}
            readOnly={isReadOnlyInput(props.editRules, InputNames.commissionCreationDateTo, readOnly)}
            required
            tooltip={lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.COMMISSION_CREATION_DATE_TO')}
            value={pgHeader.commissionCreationDateTo}
          />
        </Bricks.Column>
        <Bricks.Column colWidth='xs4'>
          <WeekPicker
            label={lsi.getLSIItem('PRICE_GUARANTEE.FORM_INPUT_LABEL.COMMISSION_PRODUCE_WEEK_FIRST_TO')}
            name={InputNames.commissionProduceWeekFirstTo}
            onChange={(opt) => handleInputChange(opt, InputNames.commissionProduceWeekFirstTo)}
            readOnly={isReadOnlyInput(props.editRules, InputNames.commissionProduceWeekFirstTo, readOnly)}
            required
            tooltip={lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.COMMISSION_PRODUCE_WEEK_FIRST_TO')}
            value={pgHeader.commissionProduceWeekFirstTo}
          />
        </Bricks.Column>
        <Bricks.Column colWidth='xs4'>
          <DatePicker
            label={lsi.getLSIItem('PRICE_GUARANTEE.FORM_INPUT_LABEL.COMMISSION_SELLING_DATE_TO')}
            name={InputNames.commissionSellingDateTo}
            onChange={(opt) => handleInputChange(opt, InputNames.commissionSellingDateTo)}
            readOnly={isReadOnlyInput(props.editRules, InputNames.commissionSellingDateTo, readOnly)}
            required
            tooltip={lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.COMMISSION_SELLING_DATE_TO')}
            value={pgHeader.commissionSellingDateTo}
          />
        </Bricks.Column>
      </Bricks.Row>
      <Bricks.Row>
        <Bricks.Column colWidth='xs5'>
          <Number
            label={lsi.getLSIItem('PRICE_GUARANTEE.FORM_INPUT_LABEL.DAYS_FROM_INVOICE_TO_SALE')}
            min={0}
            name={InputNames.daysFromInvoiceToSale}
            onChange={(opt) => handleInputChange(opt, InputNames.daysFromInvoiceToSale)}
            readOnly={isReadOnlyInput(props.editRules, InputNames.daysFromInvoiceToSale, readOnly)}
            required
            tooltip={lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.DAYS_FROM_INVOICE_TO_SALE')}
            value={pgHeader.daysFromInvoiceToSale}
          />
        </Bricks.Column>
        <Bricks.Column colWidth='xs8'/>
      </Bricks.Row>
      <Checkbox
        label={lsi.getLSIItem('PRICE_GUARANTEE.FORM_INPUT_LABEL.TWO_WEEKS_AFTER_SALE')}
        labelPosition='right'
        name={InputNames.twoWeeksAfterSale}
        onChange={(opt) => handleInputChange(opt, InputNames.twoWeeksAfterSale)}
        readOnly={isReadOnlyInput(props.editRules, InputNames.twoWeeksAfterSale, readOnly)}
        tooltip={lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.TWO_WEEKS_AFTER_SALE')}
        value={pgHeader.twoWeeksAfterSale}
      />
    </Forms.Form>
  );
};
const PriceGuaranteeFormHeader = React.memo(React.forwardRef(PriceGuaranteeForm));

PriceGuaranteeFormHeader.propTypes = propTypes;
PriceGuaranteeFormHeader.defaultProps = defaultProps;

PriceGuaranteeFormHeader.InputNames = InputNames;

export default PriceGuaranteeFormHeader;
