import { LanguageEnum as LanguageEnumClass } from '@ovex/about-web-api';

const LanguageEnum = new LanguageEnumClass();

export const LOCALE = {
  cs: LanguageEnum.cs,
  en: LanguageEnum.en,
  sk: LanguageEnum.sk
};

export const LOCALE_ORDER = [
  { language: LanguageEnum.cs, order: 10 },
  { language: LanguageEnum.sk, order: 20 },
  { language: LanguageEnum.en, order: 30 }
];

export const DEFAULT_LOCALE = LOCALE.en;

export const INITIAL_STATE = '__INITIAL_STATE__';

export const URI_JUNCTION = '';

export const ANY_SINGLE_CHARACTER = '-';

export const OVEX3_ENVIRONMENT = {
  LOCAL: 'LOCAL',
  DEVEL_1: 'DEVEL 1',
  DEVEL_2: 'DEVEL 2',
  DEVEL_3: 'DEVEL 3',
  UAT: 'UAT',
  TEST: 'TEST',
  PROD: 'PROD'
};

export const DEVELOPMENT_ENVIRONMENTS = [OVEX3_ENVIRONMENT.LOCAL, OVEX3_ENVIRONMENT.DEVEL_1, OVEX3_ENVIRONMENT.DEVEL_2, OVEX3_ENVIRONMENT.DEVEL_3, OVEX3_ENVIRONMENT.UAT];

export const FORMATTER_CODES = {
  NUMBER: 'NUMBER',
  NUMBER_0_DECIMALS: 'NUMBER_0_DECIMALS',
  NUMBER_2_DECIMALS: 'NUMBER_2_DECIMALS',
  PERCENT: 'PERCENT',
  PERCENT_0_DECIMALS: 'PERCENT_0_DECIMALS',
  PERCENT_2_DECIMALS: 'PERCENT_2_DECIMALS',
  DATE: 'DATE',
  TIME_MINUTES: 'TIME_MINUTES',
  TIME_SECONDS: 'TIME_SECONDS',
  TIME_MILLISECONDS: 'TIME_MILLISECONDS',
  DATE_TIME_MINUTES: 'DATE_TIME_MINUTES',
  DATE_TIME_SECONDS: 'DATE_TIME_SECONDS',
  DATE_TIME_MILLISECONDS: 'DATE_TIME_MILLISECONDS'
};
