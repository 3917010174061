import { EXCEL_STYLES_IDS } from '../../../../common/components/ag-grid';
import { CURRENCY_SYMBOL } from '../../../utils/const';
import PriceGuaranteeReportPageType from '../PriceGuaranteeReportPageType';

import { COLUMN_IDS } from './PriceGuaranteeReportTable.columnIds';
import {
  accountTypeFormatter,
  amountValueFormatter,
  availableValuesForRowTypeWithItemFormatter,
  clientNameFormatter,
  cohPartStatusFormatter,
  commissionClaimTypeFormatter,
  commissionPGIClaimTypeFormatter,
  cOrderModeFormatter,
  dateValueFormatter,
  dealerAreaFormatter,
  marketingActionCodeOtherFormatter,
  pgItemClaimTypeFormatter
} from './PriceGuaranteeReportTable.formatters';
import PriceGuaranteeReportRowType from './rowModel/PriceGuaranteeReportRowType';

const COLUMN_TYPE_ENUM = {
  AMOUNT_CD: 'amountDefinitionColumn',
  DEFAULT_CD: 'defaultDefinitionColumn'
};

export const columnTypes = {
  [COLUMN_TYPE_ENUM.AMOUNT_CD]: {
    cellClassRules: {
      'ovex-pg-PriceGuaranteeReportTable-cell-amount--negative': ({ value }) => value != null && value < 0
    }
  },
  [COLUMN_TYPE_ENUM.DEFAULT_CD]: {
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { buttons: ['reset'], newRowsAction: 'keep' },
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
    valueFormatter: availableValuesForRowTypeWithItemFormatter
  }
};

export const getColumnDefinitions = (lsi, currency, pageType) => {
  const currencySelected = CURRENCY_SYMBOL[currency] || ' ';

  return [
    {
      colId: COLUMN_IDS.PG_MARKETING_ACTION,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.PRICE_GUARANTEE_SHORT'),
      valueGetter: 'data.priceGuarantee.marketingActionCode',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 100,
      minWidth: 100,
      hide: true
    },
    {
      colId: COLUMN_IDS.C_COMMISSION_NUMBER,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.COMMISSION'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.COMMISSION'),
      valueGetter: 'data.commission',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      filter: 'agTextColumnFilter',
      width: 88,
      minWidth: 88
    },
    {
      colId: COLUMN_IDS.COH_ACCOUNT_TYPE,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.SALES_ACCOUNT_TYPE'),
      cellClass: [EXCEL_STYLES_IDS.STRING],
      valueGetter: 'data.accountType',
      valueFormatter: accountTypeFormatter,
      filterParams: {
        valueFormatter: ({ value, context }) => context.agLsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.SALES_ACCOUNT_TYPE.${value}`)
      },
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 120,
      minWidth: 120
    },
    {
      colId: COLUMN_IDS.C_CS_MODEL,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.MODEL'),
      valueGetter: 'data.commissionCarSpecificationModel',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      filter: 'agTextColumnFilter',
      width: 88,
      minWidth: 88
    },
    {
      colId: COLUMN_IDS.C_STATUS_CURRENT,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.STATUS_CURRENT_SHORT'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.STATUS_CURRENT'),
      valueGetter: 'data.commissionCurrentData.status',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 112,
      minWidth: 104
    },
    {
      colId: COLUMN_IDS.C_STATUS_PREVIOUS,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.STATUS_PREVIOUS_SHORT'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.STATUS_PREVIOUS'),
      valueGetter: 'data.commissionPreviousDataStatus',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 115,
      minWidth: 104
    },
    {
      colId: COLUMN_IDS.PGI_PGID,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.PGID'),
      valueGetter: 'data.pgItem?.pgid',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 152,
      minWidth: 152,
      hide: !PriceGuaranteeReportPageType.isDealer(pageType)
    },
    {
      colId: COLUMN_IDS.C_ORDER_MODE,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.MODE'),
      valueGetter: 'data.cOrderMode',
      valueFormatter: cOrderModeFormatter,
      filterParams: {
        valueFormatter: ({ value, context }) => value && context.agLsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.C_ORDER_MODE.${value}`)
      },
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      hide: true
    },
    {
      colId: COLUMN_IDS.COH_PART_STATUS,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.STATUS'),
      valueGetter: 'data.accountPartData.cohPartStatus',
      valueFormatter: cohPartStatusFormatter,
      filterParams: {
        valueFormatter: ({ value, context }) => value && context.agLsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.COH_PART_STATUS.${value}`)
      },
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      hide: true
    },
    {
      colId: COLUMN_IDS.CPGI_CLAIM_TYPE,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.CLAIM'),
      headerTooltip: [lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CLAIM_CG'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CLAIM_CG_SENT'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CLAIM_CG_SATISFIED'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CLAIM_CG_NOT_SATISFIED'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CLAIM_CG_REJECTED'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CLAIM_CG_ERROR'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CLAIM_CG_ITEM'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CLAIM_CG_ITEM_SENT'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CLAIM_CG_ITEM_SATISFIED')],
      valueGetter: (valueGetterParams) => {
        const { data } = valueGetterParams;
        if (PriceGuaranteeReportRowType.isHeaderType(data.getRowType())) {
          return data.commissionClaimTypeHeader;
        }
        return data.pgItemClaimTypeItem;
      },
      valueFormatter: commissionPGIClaimTypeFormatter,
      filterParams: {
        valueFormatter: ({ value, context }) => context.agLsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.CLAIM_TYPE_HEADER.${value}`)
      },
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 120,
      minWidth: 120
    },
    {
      colId: COLUMN_IDS.PGI_CLAIM_TYPE,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.CLAIM_ITEM'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CLAIM_ITEM'),
      valueGetter: 'data.pgItemClaimTypeItem',
      valueFormatter: pgItemClaimTypeFormatter,
      filterParams: {
        valueFormatter: ({ value, context }) => value && context.agLsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.CLAIM_TYPE_ITEM.${value}`)
      },
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 120,
      minWidth: 120,
      hide: true
    },
    {
      colId: COLUMN_IDS.C_CLAIM_TYPE,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.CLAIM_HEADER'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CLAIM_HEADER'),
      valueGetter: 'data.commissionClaimTypeHeader',
      valueFormatter: commissionClaimTypeFormatter,
      filterParams: {
        valueFormatter: ({ value, context }) => context.agLsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.CLAIM_TYPE_HEADER.${value}`)
      },
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 120,
      minWidth: 120,
      hide: true
    },
    {
      colId: COLUMN_IDS.PGI_CLAIM_AMOUNT,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.AMOUNT', currencySelected),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.AMOUNT', currencySelected),
      cellClass: ['ovex-ag-grid--td-right', EXCEL_STYLES_IDS.NUMBER_2_DECIMALS],
      valueGetter: (valueGetterParams) => {
        const { data } = valueGetterParams;

        if (PriceGuaranteeReportRowType.isHeaderType(data.getRowType())) {
          return data.accountPartData.amount;
        }
        return data.pgItemClaimAmount;
      },
      valueFormatter: amountValueFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.AMOUNT_CD],
      filter: 'agNumberColumnFilter',
      width: 165,
      minWidth: 120
    },
    ...getAmountColumnDefinitions(lsi, pageType),
    {
      colId: COLUMN_IDS.PGI_CLAIM_AMOUNT_INVOICED,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.AMOUNT_INVOICED', currencySelected),
      cellClass: ['ovex-ag-grid--td-right', EXCEL_STYLES_IDS.NUMBER_2_DECIMALS],
      valueGetter: (valueGetterParams) => {
        const { data } = valueGetterParams;

        if (PriceGuaranteeReportRowType.isHeaderType(data.getRowType())) {
          return data.accountPartData?.invoiceAmount;
        }
        return data.pgItemInvoiceAmount;
      },
      valueFormatter: amountValueFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.AMOUNT_CD],
      filter: 'agNumberColumnFilter',
      width: 172,
      minWidth: 120
    },
    {
      colId: COLUMN_IDS.C_INVOICING_DATE,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.INVOICING'),
      cellClass: ['ovex-ag-grid--td-right', EXCEL_STYLES_IDS.DATE],
      valueGetter: 'data.commissionCurrentData.invoicingDate',
      valueFormatter: dateValueFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      filter: 'agDateColumnFilter',
      width: 128,
      minWidth: 128
    },
    {
      colId: COLUMN_IDS.C_INVOICE_NUMBER,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.INVOICE_NUMBER'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.INVOICE_NUMBER'),
      valueGetter: 'data.commissionCurrentData.invoiceNumber',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 120,
      minWidth: 112
    },
    {
      colId: COLUMN_IDS.C_PRODUCE_WEEK_FIRST,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.PRODUCE_WEEK_FIRST_SHORT'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.PRODUCE_WEEK_FIRST'),
      cellClass: ['ovex-ag-grid--td-right', EXCEL_STYLES_IDS.DATE_SA],
      valueGetter: 'data.commissionCurrentData.produceWeekFirst',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 120,
      minWidth: 120
    },
    {
      colId: COLUMN_IDS.C_PRODUCTION_WEEK,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.PVT'),
      cellClass: ['ovex-ag-grid--td-right', EXCEL_STYLES_IDS.DATE_SA],
      valueGetter: 'data.commissionCurrentData.preProductionWeek',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 104,
      minWidth: 104
    },
    {
      colId: COLUMN_IDS.C_PRODUCTION_DATE,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.PRODUCTION_DATE'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.PRODUCTION_DATE_FORMAT'),
      cellClass: ['ovex-ag-grid--td-right'],
      valueGetter: 'data.commissionCurrentData.productionDate',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 105,
      minWidth: 96
    },
    {
      colId: COLUMN_IDS.C_VALUATION_DATE,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.PRICE_VALIDITY'),
      cellClass: ['ovex-ag-grid--td-right', EXCEL_STYLES_IDS.DATE],
      valueGetter: 'data.commissionCurrentData.valuationDate',
      valueFormatter: dateValueFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      filter: 'agDateColumnFilter',
      width: 128,
      minWidth: 128
    },
    {
      colId: COLUMN_IDS.C_INVOICING_DATE_TO_IMPORTER,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.INVOICING_IMPORTER'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.INVOICING_IMPORTER'),
      cellClass: ['ovex-ag-grid--td-right', EXCEL_STYLES_IDS.DATE],
      valueGetter: 'data.commissionCurrentData.invoicingDateToImporter',
      valueFormatter: dateValueFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      filter: 'agDateColumnFilter',
      width: 128,
      minWidth: 128
    },
    {
      colId: COLUMN_IDS.C_SELLING_DATE,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.SELLING'),
      cellClass: ['ovex-ag-grid--td-right', EXCEL_STYLES_IDS.DATE],
      valueGetter: 'data.commissionCurrentData.sellingDate',
      valueFormatter: dateValueFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      filter: 'agDateColumnFilter',
      width: 128,
      minWidth: 128
    },
    {
      colId: COLUMN_IDS.C_ACTIVATION_DATE,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.ACTIVATION'),
      cellClass: ['ovex-ag-grid--td-right', EXCEL_STYLES_IDS.DATE],
      valueGetter: 'data.commissionCurrentData.activationDate',
      valueFormatter: dateValueFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      filter: 'agDateColumnFilter',
      width: 128,
      minWidth: 128
    },
    {
      colId: COLUMN_IDS.C_DEALER_NUMBER,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.DEALER_SHORT'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.DEALER'),
      cellClass: [EXCEL_STYLES_IDS.STRING],
      valueGetter: 'data.commissionDealerNumber',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      filter: 'agTextColumnFilter',
      width: 88,
      minWidth: 88
    },
    {
      colId: COLUMN_IDS.C_DEALER_AREA,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.AREA'),
      valueGetter: 'data.commissionCurrentData',
      valueFormatter: dealerAreaFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 120,
      minWidth: 120
    },
    {
      colId: COLUMN_IDS.C_CLIENT_VOS,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.VOS'),
      valueGetter: 'data.commissionCurrentData.clientVos',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      filter: 'agTextColumnFilter',
      width: 120,
      minWidth: 120
    },
    {
      colId: COLUMN_IDS.C_CLIENT_ID,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.CLIENT_ID'),
      cellClass: ['ovex-ag-grid--td-right', EXCEL_STYLES_IDS.STRING],
      valueGetter: 'data.commissionCurrentData.clientId',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      filter: 'agTextColumnFilter',
      width: 100,
      minWidth: 96
    },
    {
      colId: COLUMN_IDS.C_CLIENT_NAME,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.CLIENT'),
      valueGetter: 'data.commissionCurrentData',
      valueFormatter: clientNameFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      filter: 'agTextColumnFilter',
      width: 160,
      minWidth: 160
    },
    {
      colId: COLUMN_IDS.C_MARKETING_ACTION_CODE_OTHER,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.MARKETING_ACTION'),
      valueGetter: 'data.commissionCurrentData.marketingActionCodeOther',
      valueFormatter: marketingActionCodeOtherFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      filter: 'agTextColumnFilter',
      width: 168,
      minWidth: 168
    },
    {
      colId: COLUMN_IDS.C_CALCULATION_DATE,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.CALCULATION_DATE'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CALCULATION_DATE'),
      cellClass: ['ovex-ag-grid--td-right', EXCEL_STYLES_IDS.DATE],
      valueGetter: 'data.calculationDate',
      valueFormatter: dateValueFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      filter: 'agTextColumnFilter',
      width: 148,
      minWidth: 148
    }
  ];
};

const getAmountColumnDefinitions = (lsi, pageType) => {
  if (PriceGuaranteeReportPageType.isImporter(pageType)) {
    return [
      {
        colId: COLUMN_IDS.PGI_IMPORTER_AMOUNT,
        headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.GUARANTEE_IMPORTER_AMOUNT'),
        cellClass: ['ovex-ag-grid--td-right', EXCEL_STYLES_IDS.NUMBER_2_DECIMALS],
        valueGetter: 'data.pgItem?.importerAmount',
        valueFormatter: amountValueFormatter,
        type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.AMOUNT_CD],
        filter: 'agNumberColumnFilter',
        width: 152,
        minWidth: 152,
        hide: true
      },
      {
        colId: COLUMN_IDS.PGI_HEADQUARTERS_AMOUNT,
        headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.GUARANTEE_HEADQUARTERS_AMOUNT'),
        headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.GUARANTEE_HEADQUARTERS_AMOUNT'),
        cellClass: ['ovex-ag-grid--td-right', EXCEL_STYLES_IDS.NUMBER_2_DECIMALS],
        valueGetter: 'data.pgItem?.headquartersAmount',
        valueFormatter: amountValueFormatter,
        filter: 'agNumberColumnFilter',
        type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.AMOUNT_CD],
        width: 152,
        minWidth: 152,
        hide: true
      }
    ];
  }
  return [];
};

export const getAutoGroupColumnDef = (lsi) => ({
  headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.PRICE_GUARANTEE_SHORT'),
  valueGetter: (valueGetterParams) => {
    const { data } = valueGetterParams;

    return data.priceGuarantee.marketingActionCode;
  },
  valueFormatter: (valueFormatterParams) => {
    return availableValuesForRowTypeWithItemFormatter(valueFormatterParams, true);
  },
  cellRendererParams: {
    suppressCount: true
  },
  width: 160
});
