import { combineReducers } from 'redux';

import { moduleReducerName } from '../../config/moduleConfig';

//
import userInfo from './about/userInfo/userInfo';
//
import getUserInfoWithRoleEvaluation from './change-user/user-with-role-evaluation';
//
import getAvailableBidList from './emigrate-user/available-bid-list';
import emigrateUser from './emigrate-user/emigrate-user';
//
import privilege from './privilege/privilege';
//
import initialization from './initialization/initialization';
import revertUser from './revert-user/revert-user';
import changeLanguage from './change-language/change-language';

const moduleReducers = {
  propName: moduleReducerName,
  combineReducers: combineReducers({
    userInfo: userInfo,

    getUserInfoWithRoleEvaluation: getUserInfoWithRoleEvaluation,

    getAvailableBidList: getAvailableBidList,
    emigrateUser: emigrateUser,
    changeLanguage: changeLanguage,

    revertUser: revertUser,

    privilege: privilege,

    initialization: initialization
  })
};

export default moduleReducers;
