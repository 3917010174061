import ColumnUIDefinitionCodes from '../ColumnUIDefinitionCodes';
import AbstractColumnUIDefinition from '../AbstractColumnUIDefinition';
import { CURRENCY_SYMBOL } from '../../../../../../utils/const';
import { amountValueFormatter, resolveCellClass } from '../../ValidationResultPGItemTable.helpers';

class TotalAmountCUID extends AbstractColumnUIDefinition {

  /** @override */
  getCode = () => {
    return ColumnUIDefinitionCodes.TOTAL_AMOUNT;
  }
  /** @override */
  buildColumnDefinition = (columnUIDefinition, lsi, additionalParams) => {
    const { visible } = columnUIDefinition;
    const { columnTypeList, currency } = additionalParams;

    return {
      colId: this.getCode(),
      hide: !visible,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.GUARANTEE_AMOUNT', CURRENCY_SYMBOL[currency]),
      cellClass: resolveCellClass('ovex-ag-grid--td-right'),
      field: 'totalAmount',
      valueFormatter: amountValueFormatter,
      type: [...columnTypeList],
      filter: 'agNumberColumnFilter',
      flex: 12,
      minWidth: 130
    };
  }
}

const totalAmountCUID = new TotalAmountCUID();

export default totalAmountCUID;